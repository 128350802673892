<template>
    <section class="signIn">
        <div class="form__container">
            <div class="form__left">
                <div class="form__title">
                    {{ $t('signin.title1') }}
                </div>
                <form class="form__box">
                    <div class="form__group">
                        <label class="form__name form__name-email" for="form-email">{{ $t('signin.text1') }}</label>
                        <input class="form__input" type="email" :placeholder="$t('signin.text2')" v-model="user.email"
                            v-validate="'required'" name="email">
                    </div>

                    <div class="form__group">
                        <label class="form__name form__name-password" for="form-password">{{ $t('signin.text3') }}</label>
                        <input class="form__input" type="password" :placeholder="$t('signin.text4')" v-model="user.password"
                            v-validate="'required'" name="password">
                    </div>

                    <div class="form__features">
                        <div class="form__features-wrapper">
                            <label class="form__features-label" for="form-remember" style="margin-bottom: 0;">{{
                                $t('signin.text5') }}
                                <input class="form__features-checkbox" type="checkbox" id="form-remember">
                                <span class="form__features-checkmark"></span>
                            </label>
                        </div>
                        <a class="form__features-forget" href="/reset-password">{{ $t('signin.link2') }}</a>
                    </div>

                    <button class="form__button" type="button" @click="login()">
                        <span>{{ $t('signin.btn1') }}</span>
                    </button>
                </form>

                <div class="form__sub">
                    <div class="form__sub-text">{{ $t('signin.text6') }}</div>
                    <a class="form__sub-link" href="signup">{{ $t('signin.link1') }}</a>
                </div>
            </div>

            <div class="form__right">
                <img src="../assets/img/space_one.jpeg" alt="">
            </div>
        </div>
    </section>
</template>

<script>
import CONFIG from '../config/settings.js'

export default {
    data() {
        return {
            user: {
                email: null,
                password: null,
            },
            successful: false,
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn
        }
    },
    created() {
        if (this.loggedIn) {
            window.location.replace(CONFIG.BASEURL + '/trading')
        }
    },
    methods: {
        login() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    this.$store.dispatch('auth/login', this.user).then(
                        () => {
                            this.successful = true
                            window.location.replace(CONFIG.BASEURL + '/trading')
                        },
                        error => {
                            this.$toast.error(error.response.data.message)
                            this.successful = false;
                        }
                    )
                } else {
                    let email = this.errors.has('email')
                    let password = this.errors.has('password')
                    if (email)
                        this.warning('email')
                    else if (!email && password)
                        this.warning('password')
                }
            })
        },
        warning(value) {
            if (this.errors.has(value))
                this.$toast.warning(this.errors.first(value))
        }
    }
}
</script>